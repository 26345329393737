import {
  checkPrivilege,
  getLanguage,
  getDeviceName,
  getBrowserName,
} from "./../../utils/functions";
import {
  ESTABLISHMENT_TOKEN,
  USER_PLATFORM_SESSION_VERSION_NUMBER,
  DEFAULT_PAGES,
  STATUS,
} from "./../../utils/constants";
import * as privilegeHelper from "./../../utils/privilegeHelper";
import UsersPrivilegeData from "./../../models/modulePrivileges/UsersPrivilegeData";

import axios from "axios";
import store from "./../../store";
import i18n from "./../../i18n";

export default class UserLogin {
  //#region UserLogin
  USER_PLATFORM_SESSION = {
    userPlatFormSessionVersionNumber: USER_PLATFORM_SESSION_VERSION_NUMBER,
    userPlatFormSessionLocalIP: "",
    userPlatFormSessionGlobalIP: "",
    userPlatFormSessionAddress: "",
    userPlatFormSessionLat: "",
    userPlatFormSessionLong: "",
    userPlatFormToken: "PFT-3",
    userFirebaseToken: "userFirebaseToken",
  };
  USER_DEVICE = {
    userDeviceUniqueCode: "WEB",
    userDeviceId: "",
    userDeviceName: "Unkonwn",
    userDeviceCompany: "",
    userDeviceOS: "",
    userDeviceVersionOS: getBrowserName() + " " + getDeviceName(),
    userDeviceEMUI: "",
    userDeviceRam: "",
    userDeviceProcessor: "",
    userDeviceDisplay: "",
    userDeviceDescription: "",
    userDeviceNotes: "",
  };
  USER_APP_SETTING = {
    languageToken: getLanguage(),
    themeToken: "",
    decimalFormatToken: "",
    timeZoneToken: "Egypt Standard Time",
    dateFormatToken: "dddd, dd MMMM yyyy",
    timeFormatToken: "hh:mm:ss tt",
    currencyToken: "EGP",
    printCodeTypeToken: "",
    startDayOfWeekToken: "",
    startMonthOfYear: 0,
    startDayOfMonth: 0,
    backAfterAddStatus: true,
    backAfterEditStatus: true,
    receiveNotificationStatus: true,
    customSettings: "",
  };
  //#endregion UserLogin

  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.user = "";
    this.userPassword = "";
    this.establishmentToken = ESTABLISHMENT_TOKEN;
    this.userPlatformSessionData = this.USER_PLATFORM_SESSION;
    this.userDeviceData = this.USER_DEVICE;
    this.userAppSettingData = this.USER_APP_SETTING;
  }
  fillData(data) {
    this.language = data.language;
    this.user = data.user;
    this.userPassword = data.userPassword;
    this.establishmentToken = data.establishmentToken;
    this.userPlatformSessionData = data.userPlatformSessionData;
    this.userDeviceData = data.userDeviceData;
    this.userAppSettingData = data.userAppSettingData;
  }

  async login() {
    let response = await axios.post(`/api/UsersAuthentication/UserLogin`, this);
    return response;
  }

  saveUserData(apiAppData) {
    let userHomePage = DEFAULT_PAGES.notLoggedIn;

    const userPersonalDataObject = {
      userEmail: apiAppData.userData.userEmail,
      userImagePath: apiAppData.userData.userImagePath,
      userName: apiAppData.userData.userName,
      userNameAr: apiAppData.userData.userNameAr,
      userNameCurrent: apiAppData.userData.userNameCurrent,
      userNameEn: apiAppData.userData.userNameEn,
      userNameUnd: apiAppData.userData.userNameUnd,
      userPassword: apiAppData.userData.userPassword,
      userPhone: apiAppData.userData.userPhone,
      userPhoneCountryCode: apiAppData.userData.userPhoneCountryCode,
      userPhoneCountryCodeName: apiAppData.userData.userPhoneCountryCodeName,
      userPhoneWithCC: apiAppData.userData.userPhoneWithCC,
      // establishmentRoleData: apiAppData.userData.establishmentRoleData,
      userToken: apiAppData.userData.userToken,
      userActivityTypeToken: apiAppData.userData.userActivityTypeToken,
      userTypeNameAr: apiAppData.userData.userTypeNameAr,
      userTypeNameCurrent: apiAppData.userData.userTypeNameCurrent,
      userTypeNameEn: apiAppData.userData.userTypeNameEn,
      userTypeNameUnd: apiAppData.userData.userTypeNameUnd,
      userTypeToken: apiAppData.userData.userTypeToken,
      activationTypeToken: apiAppData.userData.activationTypeToken,
    };

    let response = {
      status: STATUS.ERROR,
      msg: i18n.t("sorryYouDoNotHaveThisPermission"),
    };

    let privileges = new UsersPrivilegeData();
    // console.log(apiAppData.userData.establishmentRoleData.modulePrivilegeData);
    privileges.fillData(
      apiAppData.userData.establishmentRoleData.modulePrivilegeData
    );

    // #region userHomePage
    if (checkPrivilege(false, apiAppData.userData.userTypeToken)) {
      userHomePage = DEFAULT_PAGES.loggedIn;
    } else if (privileges.Student.view) {
      userHomePage = "Students";
    } else if (privileges.StudentEstablishmentsRole.view) {
      userHomePage = "StudentEstablishmentsRole";
    } else if (privileges.StudentValidSetting.view) {
      userHomePage = "StudentValidSetting";
    } else if (privileges.StudentLoginCode.view) {
      userHomePage = "StudentLoginCode";
    } else if (privileges.PlaceStudent.view) {
      userHomePage = "PlaceStudents";
    } else if (privileges.Supplier.view) {
      userHomePage = "Suppliers";
    } else if (privileges.SupplierEstablishmentsRole.view) {
      userHomePage = "SupplierEstablishmentsRole";
    } else if (privileges.SupplierValidSetting.view) {
      userHomePage = "SupplierValidSetting";
    } else if (privileges.SupplierLoginCode.view) {
      userHomePage = "SupplierLoginCode";
    } else if (privileges.PlaceSupplier.view) {
      userHomePage = "PlaceSuppliers";
    } else if (privileges.Parent.view) {
      userHomePage = "Parents";
    } else if (privileges.ParentEstablishmentsRole.view) {
      userHomePage = "ParentEstablishmentsRole";
    } else if (privileges.ParentValidSetting.view) {
      userHomePage = "ParentValidSetting";
    } else if (privileges.ParentLoginCode.view) {
      userHomePage = "ParentLoginCode";
    } else if (privileges.PlaceParent.view) {
      userHomePage = "PlaceParents";
    } else if (privileges.Client.view) {
      userHomePage = "Clients";
    } else if (privileges.ClientEstablishmentsRole.view) {
      userHomePage = "ClientEstablishmentsRole";
    } else if (privileges.ClientValidSetting.view) {
      userHomePage = "ClientValidSetting";
    } else if (privileges.ClientLoginCode.view) {
      userHomePage = "ClientLoginCode";
    } else if (privileges.PlaceClient.view) {
      userHomePage = "PlaceClients";
    } else if (privileges.Employee.view) {
      userHomePage = "Employees";
    } else if (privileges.EmployeeEstablishmentsRole.view) {
      userHomePage = "EmployeeEstablishmentsRole";
    } else if (privileges.EmployeeValidSetting.view) {
      userHomePage = "EmployeeValidSetting";
    } else if (privileges.EmployeeLoginCode.view) {
      userHomePage = "EmployeeLoginCode";
    } else if (privileges.PlaceEmployee.view) {
      userHomePage = "PlaceEmployees";
    } else if (privileges.MasterAdmin.view) {
      userHomePage = "MasterAdmin";
    } else if (privileges.MasterAdminEstablishmentsRole.view) {
      userHomePage = "MasterAdminEstablishmentsRole";
    } else if (privileges.MasterAdminValidSetting.view) {
      userHomePage = "MasterAdminValidSetting";
    } else if (privileges.MasterAdminLoginCode.view) {
      userHomePage = "MasterAdminLoginCode";
    } else if (privileges.PlaceMasterAdmin.view) {
      userHomePage = "PlaceMasterAdmin";
    } else if (privilegeHelper.hasSettingsOthers()) {
      userHomePage = "SettingsOthers";
    } else {
      userHomePage = DEFAULT_PAGES.notLoggedIn;
    }

    // #endregion userHomePage

    // setUserHomePage
    store.dispatch("setUserHomePage", userHomePage);

    if (userHomePage != DEFAULT_PAGES.notLoggedIn) {
      let data = {
        userAuthorizeToken: apiAppData.userAuthorizeToken,
        userPersonalDataObject,
        constantsListsData: apiAppData.constantsListsData,
        usersPrivilegeData: privileges,
      };

      // login
      store.dispatch("loginUser", data);

      response.status = STATUS.SUCCESS;
      response.msg = i18n.t("successOperation");
    }
    return response;
  }
}
